import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Loader from '../src/components/loader';
import Head from 'next/head';
import cookie from 'js-cookie';

function Four0four() {
    const [loading, setLoading] = useState(true);
    const router = useRouter();

    useEffect(() => {
        const pathSegments = router.asPath.split('/');
        if (pathSegments[3] === 'floor' || pathSegments[3] === 'wall') {
            router.push(`/tiles/${pathSegments[2]}`);
        } else {
            setLoading(false);
        }
     if(cookie.get('pincode')){
        cookie.remove('pincode')
     }
    }, [router.asPath]);

    return (
        <>
            <Head>
                <title>Page Not Found - Orientbell Tiles</title>
                <meta name="title" content='Page Not Found - Orientbell Tiles'/>
                <meta name="description" content='Page Not Found - Orientbell Tiles'/>
            </Head>
            {loading ? <Loader /> : (
                <Container>
                    <div className="error-section">
                        <div className="error-figure">
                            <img 
                                src="https://images.orientbell.com/media/page_not_found.jpg" 
                                alt="404 image" 
                                className="img-fluid"
                            />
                        </div>
                        <div className="error-content">
                            <div className="error-text-bx">
                                <p>It seems like the page you're trying to reach has moved or no longer exists.</p>
                                <p>Feel free to reach out at:</p>
                                <a className="home-btn" id='call-us-404' href="tel:18002081015">
                                <svg 
                                        stroke="currentColor" 
                                        fill="currentColor" 
                                        strokeWidth="0" 
                                        viewBox="0 0 512 512" 
                                        height="1em" 
                                        width="1em" 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        style={{ height: '30px', fontSize: '22px', marginRight: '10px' }}
                                    >
                                        <path 
                                            d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" 
                                            style={{ height: '30px', fontSize: '24px' }}
                                        />
                                    </svg> 1800-208-1015
                                    
                                </a>
                                <br />
                                <a 
                                    className="home-btn gobackhome-btn" 
                                    href="/" 
                                    style={{ backgroundColor: 'transparent', color: '#008aef', marginTop: '10px', fontSize: '20px' }}
                                >
                                    Go Back to Home...
                                </a>
                            </div>
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
}

export default Four0four;
